<template>
  <client-only>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-40" @close="onClose">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                :class="[
                  'relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6',
                  size,
                ]"
              >
                <div
                  v-if="canClose"
                  class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
                >
                  <button
                    v-tooltip="closeTooltip"
                    type="button"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
                    @click="onClose"
                  >
                    <span class="sr-only">Close</span>
                    <ButtonLoadingSpinnerIcon v-if="loading" />
                    <XMarkIcon v-else class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start w-full">
                  <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <DialogTitle
                      as="h3"
                      class="text-base font-semibold leading-6 text-gray-900"
                      data-cy="modal-title"
                      >{{ title }}</DialogTitle
                    >
                    <slot />
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <slot name="actions" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog> </TransitionRoot
  ></client-only>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";

const { open, title } = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: "sm:max-w-2xl",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  closeTooltip: {
    type: String,
    default: "Close",
  },
  canClose: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["close"]);

const onClose = () => {
  emit("close");
};
</script>
